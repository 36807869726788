import { Box, Button, ButtonDropdown, Header, Link, Pagination, SpaceBetween, Table, TextFilter } from "@cloudscape-design/components";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { DemoAssets } from "../../types";
import { useAuth } from "react-oidc-context";

export default function AdminAssetsTable() {
    const navigate = useNavigate();
    const auth = useAuth();
    const [assets, setAssets] = useState<DemoAssets[]>([]);
    const [selectedAssets, setSelectedAssets] = useState<DemoAssets[]>([]);
    const [assetsLoaded, setAssetsLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pageIndex, setPageIndex] = useState(0)
    const [multiSelectActionsDisabled, setMultiSelectActionsDisabled] = useState(true)
    const [singleItemActionsDisabled, setSingleItemActionsDisabled] = useState(true)


    useEffect(() => {
        if (!assetsLoaded) {
            try {
                console.log('Loading assets');
                setLoading(true);
                if (!auth.user?.id_token || auth.user?.id_token === '') {
                    throw new Error('No access token available');
                }
                // This is a workaround for local development where proxy is dropping the /api prefix
                const endpoint = window.location.hostname === 'localhost' ? '/api/api/assets' : '/api/assets'

                const token = auth.user.id_token
                if (!token) {
                    return;
                }
                fetch(endpoint, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                }).then((response) => {
                    if (!response.ok) {
                        response.text().then((t) => console.error(t));
                        return;
                    } else {
                        response.json().then((data) => {
                            setAssets(data);
                            setAssetsLoaded(true);
                            setLoading(false);
                        })

                    }

                })

            } catch (e) {
                console.error(e);
                setLoading(false);
            }
        }



    }, [assets, assetsLoaded, auth])

    return (
        <Table
            key="Admin-Assets-Table"
            renderAriaLive={({
                firstIndex,
                lastIndex,
                totalItemsCount
            }) =>
                `Displaying items ${firstIndex} to ${lastIndex} of ${totalItemsCount}`
            }
            onSelectionChange={({ detail }) => {
                console.log(detail.selectedItems)
                console.log(detail.selectedItems.length === 1)
                setSelectedAssets(detail.selectedItems)
                setMultiSelectActionsDisabled(detail.selectedItems.length < 1)
                setSingleItemActionsDisabled(detail.selectedItems.length !== 1)
            }}
            selectedItems={selectedAssets}
            ariaLabels={{
                selectionGroupLabel: "Item selection",
                allItemsSelectionLabel: () => "Select all items",
            }}
            columnDefinitions={[
                {
                    id: "name",
                    header: "Asset Collection Name",
                    cell: (item) => { return <Link href={`/admin/asset/${item['name']}`}>{item['name']}</Link> },
                    isRowHeader: true,
                },
                {
                    id: "description",
                    header: "Asset Description",
                    cell: (item) => item['description'],
                },
                {
                    id: "assetCount",
                    header: "Assets in Collection",
                    cell: (item: DemoAssets) => { return item.assets.length },
                }
            ]}
            resizableColumns
            columnDisplay={[
                { id: "name", visible: true },
                { id: "description", visible: true, },
                { id: "assetCount", visible: true }
            ]}
            items={assets}
            loadingText="Loading items..."
            loading={loading}
            trackBy="name"
            selectionType="multi"
            empty={
                <Box
                    key="assets-empty-box"
                    margin={{ top: "l" }}
                    textAlign="center"
                    color="inherit"
                >
                    <SpaceBetween key="assets-empty-spacer" size="m">
                        <b key="assets-empty-b-text">It's quiet... too quiet. No assets were loaded...</b>
                    </SpaceBetween>
                </Box>
            }
            filter={
                <TextFilter
                    key={"assets-text-filter"}
                    filteringPlaceholder="Find resources"
                    filteringText=""
                />
            }
            header={
                <Header
                    key="assets-header"
                    counter={
                        selectedAssets.length ? "(" + selectedAssets.length + "/" + assets.length + ")" : "(" + assets.length + ")"
                    }
                    actions={
                        <SpaceBetween direction="horizontal" size="xs">
                            <ButtonDropdown
                                items={[
                                    {
                                        text: "Enable",
                                        id: "enable",
                                        disabled: multiSelectActionsDisabled
                                    },
                                    {
                                        text: "Disable",
                                        id: "disable",
                                        disabled: multiSelectActionsDisabled
                                    }
                                ]}>Actions</ButtonDropdown>
                            <Button variant="normal" disabled={singleItemActionsDisabled}>Edit Demo</Button>
                            <Button variant="primary" onClick={() => navigate('/admin/demo')}>Add Demo</Button>
                        </SpaceBetween>

                    }
                >
                    Configured Assets
                </Header>
            }
            pagination={assets.length > 10 ?
                <Pagination
                    currentPageIndex={pageIndex}
                    pagesCount={Math.ceil(assets.length / 10)}
                    onChange={({ detail }) => setPageIndex(detail.currentPageIndex)} />
                : undefined

            }
        />
    )
}