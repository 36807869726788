/*
 *
 * Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: MIT-0
 */
import { useEffect, useState } from 'react';
import { DemoDefinition } from '../types';
import App from '../App';
import { DemoContext } from './demo-context';
import { useAuth } from 'react-oidc-context';


export default function AppConfigured() {
  const [demos, setDemos] = useState<DemoDefinition[]>([]);
  const auth = useAuth();
  useEffect(() => {
    (async () => {
      if (auth.isAuthenticated) {
        document.cookie = `auth_token=${auth.user?.access_token}; path=/; secure; samesite=strict`;
        window.history.replaceState({},
          window.document.title,
          window.location.origin + window.location.pathname);
      }
      try {
        const demoResult = await fetch(`/demos.json?rand=${process.env.REACT_APP_DEMO_ID}`);
        const demoData = (await demoResult.json()) as DemoDefinition[];
        setDemos(demoData);
        console.log('Demos loaded', demoData);
      } catch (e) {
        console.error(e);
      }
    })().catch((e) => {
      console.error(e);
    });
  }, [auth]);


  if (demos.length === 0) {
    return (
      <div>Loading...</div>
    )
  }

  return (
    <DemoContext.Provider value={demos}>
      <App />
    </DemoContext.Provider>
  );
}
