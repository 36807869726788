import { Box, Button, ButtonDropdown, Header, Link, Pagination, SpaceBetween, Table, TextFilter } from "@cloudscape-design/components";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import DemoStatusIndicator from "../DemoStatusIndicator";
import { AdminDemoDefiniton, DemoDefinition, DemoStatus } from "../../types";
import { useAuth } from "react-oidc-context";

export default function AdminDemosTable() {
    const navigate = useNavigate();
    const auth = useAuth();
    const [demos, setDemos] = useState<AdminDemoDefiniton[]>([]);
    const [selectedDemos, setSelectedDemos] = useState<AdminDemoDefiniton[]>([]);
    const [demosLoaded, setDemosLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pageIndex, setPageIndex] = useState(0)
    const [multiSelectActionsDisabled, setMultiSelectActionsDisabled] = useState(true)
    const [singleItemActionsDisabled, setSingleItemActionsDisabled] = useState(true)


    useEffect(() => {
        if (!demosLoaded) {
            console.log('Loading demos');
            setLoading(true);
            if (!auth.user?.id_token) {
                throw new Error('No authentication token available');
            }
            auth.startSilentRenew();
            const token = auth.user.id_token;
            const endpoint = window.location.hostname === 'localhost' ? '/api/api/admin/demos' : `/api/admin/demos`
            fetch(endpoint, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            }).then((response) => {
                if (!response.ok) {
                    response.text().then((t) => console.error(t));

                } else {
                    response.json().then((data) => {
                        setDemos(data);
                        setDemosLoaded(true);
                        setLoading(false);
                    })
                }
            }).catch((e) => {
                console.error(e);
                setLoading(false);
            });

        }

    }, [demos, demosLoaded, auth])

    return (
        <Table
            renderAriaLive={({
                firstIndex,
                lastIndex,
                totalItemsCount
            }) =>
                `Displaying items ${firstIndex} to ${lastIndex} of ${totalItemsCount}`
            }
            onSelectionChange={({ detail }) => {
                console.log(detail.selectedItems)
                console.log(detail.selectedItems.length === 1)
                setSelectedDemos(detail.selectedItems)
                setMultiSelectActionsDisabled(detail.selectedItems.length < 1)
                setSingleItemActionsDisabled(detail.selectedItems.length !== 1)
            }}
            selectedItems={selectedDemos}
            ariaLabels={{
                selectionGroupLabel: "Item selection",
                allItemsSelectionLabel: () => "Select all items",
            }}
            columnDefinitions={[
                {
                    id: "name",
                    header: "Demo Name",
                    cell: (item) => { return <Link href={`/admin/demo/${item['path']}`}>{item['name']}</Link> },
                    isRowHeader: true,
                },
                {
                    id: "path",
                    header: "Unique Demo Key",
                    cell: (item) => item['path'],
                },
                {
                    id: "state",
                    header: "Demo Status",
                    cell: (item: DemoDefinition) => { return item.status ? <DemoStatusIndicator status={item.status} /> : <DemoStatusIndicator status={DemoStatus.production} /> },
                }
            ]}
            columnDisplay={[
                { id: "name", visible: true },
                { id: "path", visible: true },
                { id: "state", visible: true }
            ]}
            items={demos}
            loadingText="Loading items..."
            loading={loading}
            trackBy="path"
            selectionType="multi"
            empty={
                <Box
                    margin={{ top: "l" }}
                    textAlign="center"
                    color="inherit"
                >
                    <SpaceBetween size="m">
                        <b>It's quiet... too quiet. No demos were loaded...</b>
                    </SpaceBetween>
                </Box>
            }
            filter={
                <TextFilter
                    filteringPlaceholder="Find resources"
                    filteringText=""
                />
            }
            header={
                <Header
                    counter={
                        selectedDemos.length ? "(" + selectedDemos.length + "/" + demos.length + ")" : "(" + demos.length + ")"
                    }
                    actions={
                        <SpaceBetween direction="horizontal" size="xs">
                            <ButtonDropdown
                                items={[
                                    {
                                        text: "Enable",
                                        id: "enable",
                                        disabled: multiSelectActionsDisabled
                                    },
                                    {
                                        text: "Disable",
                                        id: "disable",
                                        disabled: multiSelectActionsDisabled
                                    }
                                ]}>Actions</ButtonDropdown>
                            <Button variant="normal" disabled={singleItemActionsDisabled}>Edit Demo</Button>
                            <Button variant="primary" onClick={() => navigate('/admin/demo')}>Add Demo</Button>
                        </SpaceBetween>

                    }
                >
                    Configured Demos
                </Header>
            }
            pagination={demos.length > 10 ?
                <Pagination
                    currentPageIndex={pageIndex}
                    pagesCount={Math.ceil(demos.length / 10)}
                    onChange={({ detail }) => setPageIndex(detail.currentPageIndex)} />
                : undefined

            }
        />
    )
}