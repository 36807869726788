import { BreadcrumbGroup, Button, SpaceBetween } from '@cloudscape-design/components';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Layout } from '../../components/layout';
// import { RumContext } from '../components/rum-context';
import DemoDetails from '../../components/DemoDetails';
import { DemoDefinition } from '../../types';
import { useAuth } from 'react-oidc-context';
// import { sigV4ASignBasic } from '../utils/request-signing';

export default function AdminDemoDetails() {
    const { path } = useParams();
    const auth = useAuth();
    const navigate = useNavigate();
    // const rum = useContext(RumContext);
    const [loading, setLoading] = useState(true);
    const [demo, setDemo] = useState<DemoDefinition | null>(null);


    useEffect(() => {
        if (auth.isAuthenticated && !demo) {
            console.log('Loading demos');
            if (!auth.user?.id_token) {
                throw new Error('No access token available');
            }
            auth.startSilentRenew();
            const token = auth.user.id_token;
            if (!token) {
                return;
            }
            const endpoint = (window.location.hostname === 'localhost' ? '/api' : '') + `/api/admin/demos/${path}`;
            console.log("endpoint", endpoint)
            fetch(endpoint, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            }).then((response) => {
                if (!response.ok) {
                    response.text().then((t) => console.error(t));
                } else {
                    response.json().then((data) => {
                        setDemo(data);
                        setLoading(false);
                    });
                }
            }).catch((e) => {
                console.error(e);
                setLoading(false);
            });

        }
    }, [auth, demo, path]);

    const breadcrumbs = (
        <BreadcrumbGroup
            items={[
                { text: "Admin", href: "/admin" },
                { text: demo?.name || "Demo Details", href: `/admin/details/${path}` }
            ]}
        />
    );

    // rum?.recordPageView(`admin/details/${path}`);
    if (!demo) {
        return (
            <Layout breadcrumbs={breadcrumbs}>
                <p>Loading...</p>
            </Layout>
        );
    }

    return (
        <Layout breadcrumbs={breadcrumbs}>
            {loading && <p>Loading...</p>}
            {demo ? (
                <SpaceBetween size="l">

                    <SpaceBetween size="xs" direction="horizontal">
                        <Button
                            variant="primary"
                            onClick={() => navigate(`/admin/demo/${path}/edit`)}>
                            Edit Demo
                        </Button>
                        <Button
                            variant="normal"
                            onClick={() => {
                                // Add delete confirmation and handling
                                console.log('Delete demo:', path);
                            }}>
                            Delete Demo
                        </Button>
                    </SpaceBetween>
                    <DemoDetails {...demo} />
                </SpaceBetween>
            ) : (
                <p>Demo not found</p>
            )}
        </Layout>
    );
}
