import { Dispatch, SetStateAction } from 'react';

export enum DemoStatus {
  development="development",
  staging="staging",
  production="production",
  comingSoon="comingsoon",
}
export interface DemoDetails {
  tags?: Array<string>;
  mainDescriptor?: string;
  features?: Array<string>;
  useCases?: Array<string>;
  repositoryUrl?: string;
  repositoryName: string;
  repositoryNotes?: string;
  imageUrl?: string;
  videoUrl?: string;
  embed?: boolean;
}


export interface LoadBalancedOriginDefinition {
  loadBalancerArn: string;
  dnsName: string;
  vpcId: string;
  visible?: boolean;
  type: "load-balanced";
  status?: DemoStatus;
}

export interface S3OriginDefinition {
  bucketName: string;
  visible?: boolean;
  type: "s3";
  status?: DemoStatus;
}



interface DemoAsset {
  name: string;
  path: string;
}
export interface DemoAssets {
  description: string;
  name: string;
  assets: Array<DemoAsset>;
}



export interface DemoDefinition {
  name: string;
  description?: string;
  path: string;
  status?: DemoStatus;
  demoDetails?: DemoDetails;
  demoAssets?: Array<DemoAssets>
}

export interface AdminDemoDefiniton extends DemoDefinition {
  infrastructure?: S3OriginDefinition | LoadBalancedOriginDefinition
}

export interface UserData {
  userId: string;
  userGivenName?: string;
  userFamilyName?: string;
  setUserId: Dispatch<SetStateAction<string>>;
  setUserGivenName: Dispatch<SetStateAction<string>>;
  setUserFamilyName: Dispatch<SetStateAction<string>>;
}

export interface NavigationPanelState {
  collapsed?: boolean;
  collapsedSections?: Record<number, boolean>;
}