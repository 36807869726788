import { Header, SpaceBetween, StatusIndicator } from "@cloudscape-design/components";
import { Layout } from "../../components/layout";
import { useAuth } from "react-oidc-context";
import { useEffect, useState } from "react";
import AdminDemosTable from "../../components/admin/DemosTable";
import AdminAssetsTable from "../../components/admin/AssetsTable";

export default function Dashboard() {
    const auth = useAuth();
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        if (!auth.isAuthenticated) {
            auth.signinRedirect({
                url_state: encodeURIComponent(window.location.pathname)
            });
        }
        if (auth.user?.profile?.['custom:groups'] !== undefined && auth.user?.profile?.['custom:groups'] !== null) {
            const groups = JSON.parse(auth.user.profile['custom:groups'] as string);
            if (!groups.includes('admin')) {
                window.location.href = '/';
            }
        }
        auth.startSilentRenew();
        setLoading(false);
    }, [auth, setLoading]);
    if (loading) {
        return (
            <SpaceBetween direction='vertical' size='m' alignItems='center'>
                <StatusIndicator type="loading">Loading...</StatusIndicator>
            </SpaceBetween>)
    }
    return (
        <Layout>
            <SpaceBetween direction='vertical' size='l'>
                <Header variant='awsui-h1-sticky' description="Welcome to the Admin Dashboard.">Admin Dashboard</Header>
                <AdminDemosTable key="Admin-Demos-Table" />
                <AdminAssetsTable key="Admin-Assets-Table" />
            </SpaceBetween>
        </Layout>
    )
}