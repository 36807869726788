import React from 'react';
import { createRoot } from 'react-dom/client';
import AppConfigured from './components/app-config-wrapper';
import { AuthProvider } from 'react-oidc-context';
import { defaultRum, RumContext } from './components/rum-context';

const cognitoAuthConfig = {
  authority: `https://cognito-idp.${process.env.REACT_APP_COGNITO_REGION}.amazonaws.com/${process.env.REACT_APP_COGNITO_USER_POOL_ID}`,
  client_id: process.env.REACT_APP_COGNITO_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_COGNITO_REDIRECT_URI,
  response_type: "code",
  scope: "aws.cognito.signin.user.admin email openid profile",
};




const root = createRoot(document.getElementById('app')!);
root.render(
  <React.StrictMode>
    <AuthProvider {...cognitoAuthConfig}>
      <RumContext.Provider value={defaultRum()}>
        <AppConfigured />
      </RumContext.Provider>
    </AuthProvider>
  </React.StrictMode>,
);
