import { Button, Cards, SpaceBetween } from '@cloudscape-design/components';
import { useContext } from 'react';
import { DemoDefinition, DemoStatus } from '../types';
import { DemoContext } from './demo-context';
// import { RumContext } from './rum-context';
import DemoStatusIndicator from './DemoStatusIndicator';


export function DemoTable() {
  const items = useContext(DemoContext);
  // const rum = useContext(RumContext);
  // Sort items by status order
  const sortedItems = [...items].sort((a, b) => {
    const statusOrder = {
      [DemoStatus.production]: 0,
      [DemoStatus.staging]: 1,
      [DemoStatus.comingSoon]: 2,
      [DemoStatus.development]: 3,
    };
    return statusOrder[a.status ?? DemoStatus.production] - statusOrder[b.status ?? DemoStatus.production];
  });

  return (
    <Cards
      items={sortedItems}
      cardDefinition={{
        header: (item: DemoDefinition) => item.name,
        sections: [
          {
            id: 'image',
            content: (item: DemoDefinition) => {
              if (item.demoDetails?.imageUrl) {
                return <img
                  alt={item.name}
                  src={item.demoDetails?.imageUrl}
                  style={{
                    width: '100%',
                    height: 'auto',
                    objectFit: 'cover',
                  }}
                />;
              }
              return null;
            },
          },
          {
            id: 'description',
            content: (item: DemoDefinition) => item.description,
          },
          {
            id: 'buttons',
            content: (item: DemoDefinition) => {
              return (
                <SpaceBetween size="s" direction='horizontal'>
                  <Button
                    variant='primary'
                    disabled={item.status === DemoStatus.comingSoon}
                    onClick={() => { window.open(`live-demos/${item.path}`, '_blank'); /*rum?.recordEvent('demo_visit', { demo: item.path })*/ }}
                  >
                    {item.status === DemoStatus.comingSoon ? 'Coming Soon' : 'Visit Demo'}
                  </Button>
                  <Button variant="normal" href={`details/${item.path}`} onClick={() => {
                    // rum?.recordEvent('details_visit', { demo: item.path });
                  }}>Details</Button>
                </SpaceBetween>
              );
            },
          },
          {
            id: 'status',
            header: 'Status',
            content: (item: DemoDefinition) => { return item.status ? <DemoStatusIndicator status={item.status} /> : <DemoStatusIndicator status={DemoStatus.production} /> },
          },
        ],
      }}
    />
  );
}