import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Button, 
  Container, 
  FormField, 
  Header, 
  Input, 
  Select, 
  SpaceBetween,
  Textarea,
} from '@cloudscape-design/components';
import { AdminDemoDefiniton, DemoStatus,  } from '../../types';
import { Layout } from '../../components/layout';

export default function AddDemo() {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [path, setPath] = useState('');
  const [status, setStatus] = useState<DemoStatus | ''>('');
  const [infrastructureType, setInfrastructureType] = useState<'s3' | 'load-balanced' | ''>('');
  const [repositoryUrl, setRepositoryUrl] = useState('');
  const [repositoryName, setRepositoryName] = useState('');
  const [repositoryNotes, setRepositoryNotes] = useState('');
  const [features, setFeatures] = useState<string[]>([]);
  const [useCases, setUseCases] = useState<string[]>([]);
  const [imageUrl, setImageUrl] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  const [mainDescriptor, setMainDescriptor] = useState('');
  const [embed, setEmbed] = useState(false);
  
  // S3 specific fields
  const [bucketName, setBucketName] = useState('');
  
  // Load balanced specific fields
  const [loadBalancerArn, setLoadBalancerArn] = useState('');
  const [dnsName, setDnsName] = useState('');
  const [vpcId, setVpcId] = useState('');

  const handleSubmit = async () => {
    if (!name || !description || !path || !status) {
      // Add error handling here
      return;
    }

    const infrastructure = infrastructureType === 's3' 
      ? { 
          type: 's3' as const,
          bucketName,
        }
      : infrastructureType === 'load-balanced'
      ? { 
          type: 'load-balanced' as const,
          loadBalancerArn,
          dnsName,
          vpcId,
        }
      : undefined;

    const newDemo: AdminDemoDefiniton = {
      name,
      description,
      path,
      status,  // Status is now at top level
      demoDetails: {
        repositoryUrl,
        repositoryName,
        repositoryNotes,
        features,
        useCases,
        imageUrl: imageUrl || undefined,
        videoUrl: videoUrl || undefined,
        mainDescriptor: mainDescriptor || undefined,
        embed
      },
      ...(infrastructure && { infrastructure })
    };

    try {
      const response = await fetch('/api/admin/demos', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(newDemo)
      });
      
      if (response.ok) {
        navigate('/admin');
      }
    } catch (error) {
      console.error('Failed to save demo:', error);
    }
  };

  return (
    <Layout>
      <SpaceBetween size="l">
        <Container header={<Header variant="h2">Add New Demo</Header>}>
          <SpaceBetween size="m">
            <FormField label="Name" constraintText="Required">
              <Input
                value={name}
                onChange={e => setName(e.detail.value)}
                placeholder="Enter demo name"
                ariaRequired
              />
            </FormField>
            
            <FormField label="Description" constraintText="Required">
              <Textarea
                value={description}
                onChange={e => setDescription(e.detail.value)}
                placeholder="Enter demo description"
                ariaRequired
              />
            </FormField>
            
            <FormField label="Path" constraintText="Required">
              <Input
                value={path}
                onChange={e => setPath(e.detail.value)}
                placeholder="Enter URL-friendly path"
                ariaRequired
              />
            </FormField>
            
            <FormField label="Status" constraintText="Required">
              <Select
                selectedOption={status ? { label: status, value: status } : null}
                onChange={e => setStatus(e.detail.selectedOption?.value as DemoStatus)}
                options={[
                  { label: 'Development', value: DemoStatus.development },
                  { label: 'Staging', value: DemoStatus.staging },
                  { label: 'Production', value: DemoStatus.production },
                  { label: 'Coming Soon', value: DemoStatus.comingSoon },
                ]}
                placeholder="Choose demo status"
              />
            </FormField>

            <FormField label="Features">
              <SpaceBetween size="xs">
                {features.map((feature, index) => (
                  <SpaceBetween key={index} size="xs" direction="horizontal">
                    <Input value={feature} onChange={e => {
                      const newFeatures = [...features];
                      newFeatures[index] = e.detail.value;
                      setFeatures(newFeatures);
                    }} />
                    <Button iconName="delete-marker" onClick={() => {
                      const newFeatures = features.filter((_, i) => i !== index);
                      setFeatures(newFeatures);
                    }} />
                  </SpaceBetween>
                ))}
                <Button onClick={() => setFeatures([...features, ''])}>+</Button>
              </SpaceBetween>
            </FormField>

            <FormField label="Use Cases">
              <SpaceBetween size="xs">
                {useCases.map((useCase, index) => (
                  <SpaceBetween key={index} size="xs" direction="horizontal">
                    <Input value={useCase} onChange={e => {
                      const newUseCases = [...useCases];
                      newUseCases[index] = e.detail.value;
                      setUseCases(newUseCases);
                    }} />
                    <Button iconName="delete-marker" onClick={() => {
                      const newUseCases = useCases.filter((_, i) => i !== index);
                      setUseCases(newUseCases);
                    }} />
                  </SpaceBetween>
                ))}
                <Button onClick={() => setUseCases([...useCases, ''])}>+</Button>
              </SpaceBetween>
            </FormField>

            <FormField label="Repository Information">
              <SpaceBetween size="m">
                <Input
                  placeholder="Repository Name"
                  value={repositoryName}
                  onChange={e => setRepositoryName(e.detail.value)}
                />
                <Input
                  placeholder="Repository URL"
                  value={repositoryUrl}
                  onChange={e => setRepositoryUrl(e.detail.value)}
                />
                <Textarea
                  placeholder="Repository Notes"
                  value={repositoryNotes}
                  onChange={e => setRepositoryNotes(e.detail.value)}
                />
              </SpaceBetween>
            </FormField>

            <FormField label="Infrastructure Type">
              <Select
                selectedOption={infrastructureType ? { label: infrastructureType, value: infrastructureType } : null}
                onChange={e => setInfrastructureType(e.detail.selectedOption?.value as 's3' | 'load-balanced' | '')}
                options={[
                  { label: 'S3', value: 's3' },
                  { label: 'Load Balanced', value: 'load-balanced' }
                ]}
                placeholder="Choose infrastructure type (optional)"
              />
            </FormField>

            {infrastructureType === 's3' && (
              <FormField label="S3 Bucket Name">
                <Input value={bucketName} onChange={e => setBucketName(e.detail.value)} />
              </FormField>
            )}

            {infrastructureType === 'load-balanced' && (
              <SpaceBetween size="m">
                <FormField label="Load Balancer ARN">
                  <Input value={loadBalancerArn} onChange={e => setLoadBalancerArn(e.detail.value)} />
                </FormField>
                <FormField label="DNS Name">
                  <Input value={dnsName} onChange={e => setDnsName(e.detail.value)} />
                </FormField>
                <FormField label="VPC ID">
                  <Input value={vpcId} onChange={e => setVpcId(e.detail.value)} />
                </FormField>
              </SpaceBetween>
            )}
            
            <FormField label="Main Descriptor (Optional)">
              <Input
                value={mainDescriptor}
                onChange={e => setMainDescriptor(e.detail.value)}
                placeholder="Short descriptive text for the demo"
              />
            </FormField>

            <FormField label="Image URL (Optional)">
              <Input
                value={imageUrl}
                onChange={e => setImageUrl(e.detail.value)}
                placeholder="URL to demo preview image"
              />
            </FormField>

            <FormField label="Video URL (Optional)">
              <Input
                value={videoUrl}
                onChange={e => setVideoUrl(e.detail.value)}
                placeholder="URL to demo preview video"
              />
            </FormField>

            <FormField label="Embed">
              <Select
                selectedOption={{ label: embed ? 'Yes' : 'No', value: embed.toString() }}
                onChange={e => setEmbed(e.detail.selectedOption.value === 'true')}
                options={[
                  { label: 'Yes', value: 'true' },
                  { label: 'No', value: 'false' }
                ]}
              />
            </FormField>

            <SpaceBetween size="xs" direction="horizontal">
              <Button onClick={() => navigate('/admin')}>Cancel</Button>
              <Button variant="primary" onClick={handleSubmit}>Create Demo</Button>
            </SpaceBetween>
          </SpaceBetween>
        </Container>
      </SpaceBetween>
    </Layout>
  );
}
