import '@cloudscape-design/global-styles/index.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/HomePage';
import Demos from './pages/Demos';
import { useAuth } from "react-oidc-context";
import { Alert, Button, Container, Header, SpaceBetween, StatusIndicator } from '@cloudscape-design/components';
import { Divider } from '@mui/material';
// import { useContext } from 'react';
// import { RumContext } from './components/rum-context';
import AdminDashboard from './pages/admin/Dashboard';
import AdminAddDemo from './pages/admin/AddDemo';
import DemoDetailsPage from './pages/DemoDetailsPage';
import AdminDemoDetails from './pages/admin/DemoDetails';

export default function App() {
  const auth = useAuth();
  // const rum = useContext(RumContext);
  if (auth.isLoading) {
    return (
      <SpaceBetween direction='vertical' size='m' alignItems='center'>
        <Container>
          <SpaceBetween direction='vertical' size='m' alignItems='center'>
            <Header variant='awsui-h1-sticky'>EdTech Demo Portal</Header>
            <StatusIndicator type='loading'>Please wait...</StatusIndicator>
          </SpaceBetween>
        </Container>
      </SpaceBetween>
    )
  }
  if (auth.error) {
    // rum?.recordEvent('auth_error', { message: auth.error.message });
    return (
      <SpaceBetween direction='vertical' size='m' alignItems='center'>
        <Container>
          <SpaceBetween direction='vertical' size='m' alignItems='center'>
            <Header variant='awsui-h1-sticky'>EdTech Demo Portal</Header>
            <Header variant='h2'>Please sign in with Midway to continue</Header>
            <Button onClick={() => auth.signinRedirect({
              url_state: encodeURIComponent(window.location.pathname)
            })}>Sign in with Midway</Button>
            <Divider />
            <Alert type='error' statusIconAriaLabel='Error'
              header="An error occurred">{auth.error.message}</Alert>
          </SpaceBetween>
        </Container>
      </SpaceBetween>

    )
  }
  if (auth.isAuthenticated) {
    if (auth.user?.url_state) {
      window.location.href = decodeURIComponent(auth.user?.url_state as string);
    }
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="details/:path" element={<DemoDetailsPage />} />
          <Route path="demo/:path" element={<Demos />} />
          <Route path="/admin" element={<AdminDashboard />} />
          <Route path="/admin/demo" element={<AdminAddDemo />} />
          <Route path="/admin/demo/:path" element={<AdminDemoDetails />} />
        </Routes>
      </BrowserRouter>
    );
  }
  return (
    <SpaceBetween direction='vertical' size='m' alignItems='center'>
      <Container>
        <SpaceBetween direction='vertical' size='m' alignItems='center'>
          <Header variant='awsui-h1-sticky'>EdTech Demo Portal</Header>
          <Header variant='h2'>Please sign in with Midway to continue</Header>
            <Button onClick={() => auth.signinRedirect({
              url_state: encodeURIComponent(window.location.pathname)
            })}>Sign in with Midway</Button>
        </SpaceBetween>
      </Container>
    </SpaceBetween>
  )

}

