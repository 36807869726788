import '../style/root.css'
import { AppLayout, HelpPanel, TopNavigation } from '@cloudscape-design/components';
import { ReactNode, useCallback } from 'react';
import { Divider } from '@mui/material';
import { Link } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import { createPortal } from 'react-dom';

interface LayoutProps {
  children: React.ReactNode;
  breadcrumbs?: React.ReactNode;
}

interface DemoPortalHeaderProps {
  children: ReactNode;
}

const DemoPortalHeader = ({ children }: DemoPortalHeaderProps) => {
  const domNode = document.querySelector('#header')!;
  return createPortal(children, domNode);
};


export function Layout({ children, breadcrumbs }: LayoutProps) {
  const auth = useAuth();
  const handleSignOut = useCallback(async () => {
    console.log('Signing out');
    const clientId = process.env.REACT_APP_COGNITO_CLIENT_ID!;
    const logoutUri = process.env.REACT_APP_LOGOUT_URI!;
    const cognitoDomain = process.env.REACT_APP_COGNITO_DOMAIN!
    auth.signoutSilent();
    window.location.href = `${cognitoDomain}/logout?client_id=${clientId}&logout_uri=${encodeURIComponent(logoutUri)}`;
  }, [auth]);

  return (
    <>
    <DemoPortalHeader>
      <TopNavigation
        identity={{
          href: '/',
          title: 'EdTech Demo Portal',
        }}
        utilities={[
          {
            type: 'button',
            text: `Hi, ${auth?.user?.profile.given_name ?? 'User'}`,
          },
          {
            type: 'button',
            text: 'Logout',
            onClick: handleSignOut,
            external: false,
          },
        ]}
      />
      </DemoPortalHeader>
      <AppLayout
        
        navigationHide={true}
        breadcrumbs={breadcrumbs}
        tools={<HelpPanel header={<h2>EdTech Demo Portal Help</h2>}>
          <p>Welcome to the EdTech Demo Portal. This portal is a collection of live demos that meet common EdTech related use cases.</p>
          <p>This portal is for Amazonian use only.</p>
          <Divider />
          <p>Have questions? Want to chat? Join the conversation in Slack in the <Link target='_blank' to="https://amazon.enterprise.slack.com/archives/C086CE9RYRW">#edtech-demo-portal</Link> channel</p>
        </HelpPanel>}
        content={children}
      />
    </>
  );
}
