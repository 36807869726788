import '@cloudscape-design/global-styles/index.css';
import { DemoTable } from '../components/demo-table';
import { Layout } from '../components/layout';
import { Alert, Header, SpaceBetween } from '@cloudscape-design/components';
// import { useContext } from 'react';
// import { RumContext } from '../components/rum-context';


export default function Home() {
  // const rum = useContext(RumContext);
  // rum?.recordPageView('home');
  return (
    <Layout>
      <SpaceBetween direction='vertical' size='m'>
        <Header variant='awsui-h1-sticky' description="The EdTech Demo Portal is designed to provide Amazonians access to live demo resources that meet common use cases across the EdTech vertical.">Welcome</Header>
        <Alert type='warning'>This portal is for use by Amazonians only and is <strong>not</strong> approved for customer data or any confidential data.</Alert>
        <DemoTable />
      </SpaceBetween>
    </Layout>
  );
}

