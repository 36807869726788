import { Button, Container, ExpandableSection, Header, Link, SpaceBetween } from '@cloudscape-design/components';
import '@cloudscape-design/global-styles/index.css';
// import { useContext } from 'react';
import { DemoDefinition, DemoStatus } from '../types';
import GitHubIcon from '@mui/icons-material/GitHub';
// import { RumContext } from '../components/rum-context';
import DemoStatusIndicator from '../components/DemoStatusIndicator';


export default function DemoDetails({ name, path, demoAssets, demoDetails, description, status } : DemoDefinition) {
    // const rum = useContext(RumContext)
    console.log("DemoDetails", name, path, demoAssets, description, status);


    return (
            <SpaceBetween key="space-main" direction='vertical' size='m'>
                <Container key="main-demo-container">
                    <SpaceBetween key="space-sub-main" direction='vertical' size='m'>
                        <SpaceBetween key="space-main-header" direction='vertical' size='xxxs'>
                            <Header key="main-demo-header" variant='h1' description={description}>
                                {name}
                            </Header>
                            {status ? (
                                <DemoStatusIndicator status={status} />
                            ) : null}
                        </SpaceBetween>
                        {status === DemoStatus.comingSoon ? (
                            <Button key="coming-soon-button" variant='primary' disabled>
                                Coming Soon
                            </Button>
                        ) : (
                            <Button key="main-demo-button" variant='primary' onClick={() => { window.open(`/live-demos/${path}`, '_blank'); /*rum?.recordEvent('demo_visit', { demo: path })*/ }}>
                                Visit Demo
                            </Button>
                        )}
                        {demoDetails?.mainDescriptor ? (
                            <SpaceBetween key="space-overview-header" direction='vertical' size='xs'>
                                <Header key="overivew-header" variant='h3' >
                                    Overview
                                </Header>
                                <p>{demoDetails?.mainDescriptor}</p>
                            </SpaceBetween>
                        ) : null}
                        {demoDetails?.features ? (
                            <SpaceBetween key="space-features-header" direction='vertical' size='xs'>
                                <Header key="features-header" variant='h3'>
                                    Features
                                </Header>
                                <ul>
                                    {demoDetails?.features.map((feature, index) => (
                                        <li key={index + 'feat'}>{feature}</li>
                                    ))}
                                </ul>
                            </SpaceBetween>
                        ) : null}
                        {demoDetails?.useCases ? (
                            <SpaceBetween key="space-use-cases-header" direction='vertical' size='xs'>
                                <Header key="use-cases-header" variant='h3'>
                                    Use Cases
                                </Header>
                                <ul>
                                    {demoDetails?.useCases.map((useCase, index) => (
                                        <li key={index + 'usecase'}>{useCase}</li>
                                    ))}
                                </ul>
                            </SpaceBetween>
                        ) : null}
                        {demoAssets ? (
                            <SpaceBetween key="space-assets-header" direction='vertical' size='xs'>
                                <Header key="assets-header" variant='h3' description="Download assets to help you demo the solution to a customer. Click to expand each asset group.">
                                    Assets
                                </Header>
                                {demoAssets.map((assetGroup, index) => (
                                    <ExpandableSection 
                                        key={index + 'asset-group'} 
                                        headerText={assetGroup.name}
                                        headerDescription={assetGroup.description}
                                        variant='stacked'
                                    >
                                        <ul>
                                            {assetGroup.assets.map((asset, index) => (
                                                <li key={index + 'asset'}>
                                                    <Link key={index + 'asset-link'}  href={'/' + asset.path} external target='_blank'>{asset.name}</Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </ExpandableSection>
                                ))}
                            </SpaceBetween>
                        ): null }
                        {demoDetails?.videoUrl ? (
                            <SpaceBetween key="space-video-header" direction='vertical' size='xs'>
                                <Header key="video-header" variant='h3' description="Watch a walkthrough of the demo, learn best practices and how to demo the solution to a customer.">
                                    Video Walkthrough
                                </Header>
                                <iframe key="video-embed" onClick={() => { /*rum?.recordEvent('details_video_interaction', { demo: path });*/ }} src={demoDetails?.videoUrl} title={name} width='100%' height='315' loading="lazy" style={{ border: 0, maxWidth: "560px" }} allowFullScreen>

                                </iframe>
                            </SpaceBetween>
                        ) : null}
                        {demoDetails?.repositoryUrl ? (
                            <SpaceBetween key="space-demo-repo" direction='vertical' size='xxs'>
                                <Header key="repo-header" variant='h3'>
                                    Repository
                                </Header>
                                <SpaceBetween key="space-demo-repo-sub" direction='horizontal' size='xxs'>
                                    {demoDetails?.repositoryUrl.includes('github.com') ? (
                                        <GitHubIcon key="gh-icon" color='primary' />
                                    ) : null}
                                    {demoDetails?.repositoryName ? (
                                        <strong key={'gh-repo-name'}>{demoDetails?.repositoryName}</strong>
                                    ) : null}
                                    <Link key="link-repo" onClick={() => {
                                        /*rum?.recordEvent('repo_link_clicked', {
                                            demo: path,
                                            repo: demoDetails?.repositoryUrl
                                        })*/
                                    }} external href={demoDetails?.repositoryUrl} target='_blank'>{demoDetails?.repositoryUrl}</Link>
                                    {demoDetails?.repositoryNotes ? (
                                        <div key="repo-notes">{demoDetails?.repositoryNotes}</div>
                                    ) : null}
                                </SpaceBetween>

                            </SpaceBetween>
                        ) : null}
                    </SpaceBetween>
                </Container>
            </SpaceBetween>
    );
}

