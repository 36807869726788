import '@cloudscape-design/global-styles/index.css';
import { Layout } from '../components/layout';
import { useContext, useRef, useState } from 'react';
// import { RumContext } from '../components/rum-context';
import { useParams } from 'react-router-dom';
import { BreadcrumbGroup } from '@cloudscape-design/components';
import { DemoContext } from '../components/demo-context';


export default function Demo() {
    // const rum = useContext(RumContext);
    const { path } = useParams();
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const [iframeHeight, setIframeHeight] = useState("100%");
    const demos = useContext(DemoContext);
    const demo = demos.find((demo) => demo.path === path);
    const iframeSrc = `/live-demos/${path}/?embed=true`;
    const onLoad = () => {
        window.addEventListener('message', (event) => {
            if (event.data.type === 'resize') {
                setIframeHeight(event.data.height + 'px');
            }
        });
        
        if (iframeRef.current?.contentWindow) {
            iframeRef.current.contentWindow.postMessage({ type: 'requestHeight' }, '*');
        }
    };
    // rum?.recordPageView('home');
    const breadcrumbs = (
        <BreadcrumbGroup
            items={[
                { text: "Demos", href: "/" },
                { text: demo?.name ? `${demo?.name} Details` : "Demo Details", href: `/details/${path}` },
                { text: "Live Demo", href: `/demo/${path}` }
            ]}
        />
    );
    return (
        <Layout breadcrumbs={breadcrumbs}>
            <iframe ref={iframeRef} onLoad={onLoad} src={iframeSrc} style={{ width: '100%', height: iframeHeight, border: 'none' }} title='Live Demo' />
        </Layout>
    );
}

