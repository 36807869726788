import { BreadcrumbGroup } from '@cloudscape-design/components';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { DemoContext } from '../components/demo-context';
import { Layout } from '../components/layout';
// import { RumContext } from '../components/rum-context';
import DemoDetails from '../components/DemoDetails';

export default function DemoDetailsPage() {
    const { path } = useParams();
    // const rum = useContext(RumContext);
    const demos = useContext(DemoContext);
    const demo = demos.find((demo) => demo.path === path);
    console.log('DemoDetailsPage', demo);

    const breadcrumbs = (
        <BreadcrumbGroup
            items={[
                { text: "Demos", href: "/" },
                { text: demo?.name || "Demo Details", href: `/details/${path}` }
            ]}
        />
    );

    // rum?.recordPageView(`details/${urlSlug}`);
    if(!demo) {
        return (
            <Layout breadcrumbs={breadcrumbs}>
                <p>Loading...</p>
            </Layout>
        );
    }
    return (
        <Layout breadcrumbs={breadcrumbs}>
            <DemoDetails {...demo} />
        </Layout>
    );
}
