import { StatusIndicator } from "@cloudscape-design/components";
import { DemoStatus } from "../types";

export interface DemoStatusIndicatorProps {
    status: DemoStatus;
}

export default function DemoStatusIndicator({ status }: DemoStatusIndicatorProps) {
    switch (status) {
        case DemoStatus.development:
            return <StatusIndicator type="in-progress">In Development</StatusIndicator>;
        case DemoStatus.staging:
            return <StatusIndicator type="pending">Pre-Release</StatusIndicator>;
        case DemoStatus.comingSoon:
            return <StatusIndicator type="pending">Demo Coming Soon</StatusIndicator>;
        case DemoStatus.production:
        default:
            return <StatusIndicator type="success">Ready to Demo</StatusIndicator>;
    }
}