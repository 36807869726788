/*
 *
 * Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: MIT-0
 */
import { AwsRum, AwsRumConfig } from 'aws-rum-web';
import { createContext } from 'react';

export const defaultRum = () => {
    try {
        const config: AwsRumConfig = {
          sessionSampleRate: 1,
          identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
          endpoint: "https://dataplane.rum.us-east-1.amazonaws.com",
          telemetries: ["performance","errors","http"],
          allowCookies: true,
          enableXRay: true,
        };
      
        const APPLICATION_ID: string = process.env.REACT_APP_RUM_APP_ID!;
        const APPLICATION_VERSION: string = '1.0.0';
        const APPLICATION_REGION: string = 'us-east-1';
      
        return new AwsRum(
          APPLICATION_ID,
          APPLICATION_VERSION,
          APPLICATION_REGION,
          config
        );
      } catch (error) {
        // Ignore errors thrown during CloudWatch RUM web client initialization
      }
      return null;
}

export const RumContext = createContext<AwsRum | null>(null);
